import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { TriggeredByOptions } from '../../../../utils/bi/consts';
import settingsParams from '../../settingsParams';

export type SetSelectedMonth = (
  localDateTime: string,
  triggeredBy: TriggeredByOptions,
) => Promise<void>;

export function createSetSelectedMonthAction({
  getControllerState,
  context: { calendarApi, biLogger, settings },
}: ActionFactoryParams<CalendarState, CalendarContext>): SetSelectedMonth {
  return async (localDateTime: string, triggeredBy: TriggeredByOptions) => {
    const [state, setState] = getControllerState();
    const { selectedTimezone, filterOptions, selectedService } = state;
    const onlyAvailableSlots = settings.get(settingsParams.onlyAvailableSlots);
    const selectedMonth = localDateTime;
    setState({
      selectedMonth,
    });

    let dateAvailability;
    try {
      dateAvailability = await calendarApi.getDateAvailability({
        from: selectedMonth,
        timezone: selectedTimezone!,
        filterOptions,
        onlyAvailableSlots,
        service: selectedService,
      });
    } catch (e) {}

    setState({
      dateAvailability,
    });

    biLogger.bookingsCalendarDatePickerLoad({
      dateAvailability: JSON.stringify({
        datesWithAvailableSlots:
          dateAvailability?.availabilityEntries?.filter(
            (availabilityEntry: any) => availabilityEntry.hasBookableSlots,
          ).length || 0,
        datesWithUnavailableSlots:
          dateAvailability?.availabilityEntries?.filter(
            (availabilityEntry: any) => !availabilityEntry.hasBookableSlots,
          ).length || 0,
      }),
      triggeredBy,
    });
  };
}
