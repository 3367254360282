import { SetError } from '../../../../utils/bi/consts';
import { SetCalendarErrors } from '../setCalendarErrors/setCalendarErrors';
import {
  BookingPreference,
  SelectedBookingPreference,
} from '../../../../utils/bookingPreferences/bookingPreferences';

export const updateCalendarErrors = (
  bookingPreferences: BookingPreference[],
  setCalendarErrorsAction: SetCalendarErrors,
  selectedBookingPreferences?: SelectedBookingPreference[],
) => {
  bookingPreferences.forEach((bookingPreference: BookingPreference) => {
    const isMultipleOptions = bookingPreference.options.length > 1;

    const isOptionSelected = selectedBookingPreferences?.some(
      (selectedPreference: SelectedBookingPreference) =>
        selectedPreference.key === bookingPreference.key,
    );

    if (isMultipleOptions && !isOptionSelected) {
      setCalendarErrorsAction(bookingPreference.error.key, SetError.ADD);
    }
  });
};
