import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { ServiceLocationType } from '@wix/bookings-uou-types';

export enum FilterTypes {
  LOCATION = 'LOCATION',
  STAFF_MEMBER = 'STAFF_MEMBER',
}

export type FilterOption = {
  label: string;
  value: string;
  selected: boolean;
};

export type FilterViewModel = {
  id: FilterTypes;
  label: string;
  options: FilterOption[];
  note?: string;
};

export function createFilterViewModels({
  state,
  context,
}: ViewModelFactoryParams<CalendarState, CalendarContext>): FilterViewModel[] {
  const { t } = context;
  const { selectedService, filterOptions } = state;
  const availableLocations = selectedService.locations;
  const availableStaffMembers = selectedService.staffMembers;
  const filterViewModels: FilterViewModel[] = [];

  if (availableLocations.length > 1) {
    const selectedLocationsOptions = filterOptions.LOCATION;
    filterViewModels.push({
      label: t('filter.location.label'),
      options: availableLocations
        .filter(({ type }) => type === ServiceLocationType.OWNER_BUSINESS)
        .map(({ businessLocation }) => ({
          selected: selectedLocationsOptions.some(
            (selectedLocationsOption) =>
              selectedLocationsOption === businessLocation?.id,
          ),
          label: businessLocation?.name || '',
          value: businessLocation?.id || '',
        })),
      id: FilterTypes.LOCATION,
    });
  }

  if (availableStaffMembers.length > 1) {
    const selectedStaffMembersOptions = filterOptions.STAFF_MEMBER;
    filterViewModels.push({
      label: t('filter.staff-member.label'),
      options: availableStaffMembers.map(({ id, name }) => ({
        selected: selectedStaffMembersOptions.some(
          (selectedStaffMembersOption) => selectedStaffMembersOption === id,
        ),
        label: name,
        value: id,
      })),
      id: FilterTypes.STAFF_MEMBER,
    });
  }

  return filterViewModels;
}
