import { mockDateAvailability } from './mockGetSlotsForSelectedDate';
import { getDateTimeFromLocalDateTime } from '../../../src/utils/dateAndTime/dateAndTime';

export function mockGetDateAvailabilityResponse() {
  const availabilityEntries = createDummyAvailabilityEntries(
    [
      getDateTimeFromLocalDateTime(mockDateAvailability.dateWithSlots1),
      getDateTimeFromLocalDateTime(mockDateAvailability.dateWithSlots2),
      getDateTimeFromLocalDateTime(mockDateAvailability.dateWithSlots3),
    ],
    [
      getDateTimeFromLocalDateTime(mockDateAvailability.dateWithNoSlots1),
      getDateTimeFromLocalDateTime(mockDateAvailability.dateWithNoSlots2),
      getDateTimeFromLocalDateTime(mockDateAvailability.dateWithNoSlots3),
    ],
  );
  const queryDateAvailabilityResponse = {
    availabilityEntries,
    nextAvailable: {
      date: getDateTimeFromLocalDateTime(mockDateAvailability.dateWithSlots1),
    },
    pagingMetadata: {},
  };
  return queryDateAvailabilityResponse;
}

const createDummyAvailabilityEntries = (
  availableDates: Date[] = [],
  notAvailableDates: Date[] = [],
) => {
  return [
    ...availableDates.map((date) => ({
      bookingPolicyViolations: {
        isNoBookFlow: false,
        isTooEarlyToBook: false,
        isTooLateToBook: false,
      },
      date,
      hasBookableSlots: true,
      hasWaitingListSlots: false,
    })),
    ...notAvailableDates.map((date) => ({
      bookingPolicyViolations: {
        isNoBookFlow: false,
        isTooEarlyToBook: false,
        isTooLateToBook: false,
      },
      date,
      hasBookableSlots: false,
      hasWaitingListSlots: false,
    })),
  ];
};
