import { DialogType } from '../../components/BookingCalendar/ViewModel/dialogViewModel/dialogViewModel';
import { WidgetComponents } from './consts';
import { Optional } from '../../types/types';

export const mapDialogTypeToWidgetComponent = (
  dialogType: Optional<DialogType>,
): Optional<WidgetComponents> => {
  if (dialogType === DialogType.RescheduleConfirm) {
    return WidgetComponents.RESCHEDULE_DIALOG;
  }
  if (dialogType === DialogType.JoinWaitlist) {
    return WidgetComponents.JOIN_WAITLIST_DIALOG;
  }
};
