import {
  ActionFactoryParams,
  SetState,
} from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { SetCalendarErrors } from '../setCalendarErrors/setCalendarErrors';
import {
  Preference,
  SetError,
  TriggeredByOptions,
} from '../../../../utils/bi/consts';
import { getBookingPreferencesForSelectedTime } from '../../../../utils/bookingPreferences/bookingPreferencesForSelectedTime';
import {
  BookingPreference,
  SelectedBookingPreference,
} from '../../../../utils/bookingPreferences/bookingPreferences';
import { isSelectedBookingPreferenceWithWaitingList } from '../../../../utils/selectedBookingPreferences/selectedBookingPreferences';
import { CalendarBiLogger } from '../../../../utils/bi/biLoggerFactory';
import { Optional } from '../../../../types/types';

export type OnBookingPreferenceOptionSelected = (
  selectedSlotOption: SelectedBookingPreference,
) => Promise<void>;

export function createOnBookingPreferenceOptionSelectedAction(
  {
    getControllerState,
    context,
  }: ActionFactoryParams<CalendarState, CalendarContext>,
  setCalendarErrorsAction: SetCalendarErrors,
): OnBookingPreferenceOptionSelected {
  return async (selectedBookingPreference: SelectedBookingPreference) => {
    const [state, setState] = getControllerState();
    const { biLogger, settings, t, businessInfo } = context;
    const {
      selectedBookingPreferences: previouslySelectedBookingPreferences,
      selectedTime,
      selectableSlotsAtSelectedTime,
      calendarErrors,
    } = state;

    if (selectableSlotsAtSelectedTime) {
      const dateRegionalSettingsLocale = businessInfo.dateRegionalSettingsLocale!;
      const bookingPreferences: BookingPreference[] = getBookingPreferencesForSelectedTime(
        {
          selectableSlotsAtSelectedTime,
          calendarErrors,
          t,
          settings,
          dateRegionalSettingsLocale,
          selectedBookingPreferences: previouslySelectedBookingPreferences,
        },
      );

      updateCalendarErrors({
        bookingPreferences,
        selectedBookingPreference,
        setCalendarErrorsAction,
      });

      updateSelectedBookingPreferences({
        previouslySelectedBookingPreferences,
        selectedBookingPreference,
        setState,
      });

      sendBookingDetailsLoadedBiEvent({
        previouslySelectedBookingPreferences,
        selectedBookingPreference,
        bookingPreferences,
        selectedTime,
        biLogger,
      });
    }
  };
}

const updateCalendarErrors = ({
  bookingPreferences,
  selectedBookingPreference,
  setCalendarErrorsAction,
}: {
  bookingPreferences: BookingPreference[];
  selectedBookingPreference: SelectedBookingPreference;
  setCalendarErrorsAction: SetCalendarErrors;
}) => {
  const selectedPreference = bookingPreferences.filter(
    (preference: BookingPreference) =>
      preference.key === selectedBookingPreference.key,
  );
  const preferenceError = selectedPreference?.[0]?.error.key;

  if (preferenceError) {
    setCalendarErrorsAction(preferenceError, SetError.REMOVE);
  }
};

const updateSelectedBookingPreferences = ({
  previouslySelectedBookingPreferences,
  selectedBookingPreference,
  setState,
}: {
  previouslySelectedBookingPreferences: SelectedBookingPreference[];
  selectedBookingPreference: SelectedBookingPreference;
  setState: SetState<CalendarState>;
}) => {
  const previouslySelectedPreferencesOnlyFromOtherPreferences = previouslySelectedBookingPreferences.filter(
    (previouslySelectedBookingPreference: SelectedBookingPreference) =>
      previouslySelectedBookingPreference.key !== selectedBookingPreference.key,
  );
  const selectedBookingPreferences = [
    ...previouslySelectedPreferencesOnlyFromOtherPreferences,
    selectedBookingPreference,
  ];

  setState({
    selectedBookingPreferences,
  });
};

const sendBookingDetailsLoadedBiEvent = ({
  previouslySelectedBookingPreferences,
  selectedBookingPreference,
  bookingPreferences,
  selectedTime,
  biLogger,
}: {
  previouslySelectedBookingPreferences: SelectedBookingPreference[];
  selectedBookingPreference: SelectedBookingPreference;
  bookingPreferences: BookingPreference[];
  selectedTime: Optional<string>;
  biLogger: CalendarBiLogger;
}) => {
  void biLogger.bookingsCalendarBookingDetailsLoad({
    triggeredBy: getPreferenceTriggeredBy(selectedBookingPreference.key),
    selectedSlot: selectedTime,
    properties: JSON.stringify({
      previouslySelectedBookingPreferences,
      selectedBookingPreference: {
        ...selectedBookingPreference,
        ...(isSelectedBookingPreferenceWithWaitingList(
          selectedBookingPreference,
          bookingPreferences,
        )
          ? { waitlist: true }
          : {}),
      },
    }),
  });
};

const getPreferenceTriggeredBy = (preference: Preference) => {
  switch (preference) {
    case Preference.STAFF_MEMBER:
      return TriggeredByOptions.STAFF_MEMBER_BOOKING_PREFERENCE_SELECTED;
    case Preference.LOCATION:
      return TriggeredByOptions.LOCATION_BOOKING_PREFERENCE_SELECTED;
    case Preference.DURATION:
      return TriggeredByOptions.BOOKING_DETAILS_BOOKING_PREFERENCE_SELECTED;
    default:
      return '';
  }
};
